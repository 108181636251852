<template>
    <section class="form-container">
        <section class="form-item">
            <a-form :form="form" layout="inline">
                <a-row>
                    <a-form-item label="角色名称">
                        <a-input style="width:300px" placeholder="请输入" v-decorator="['name',{rules:[{required:true,message:'请输入角色名称'}]}]"></a-input>
                    </a-form-item>
                </a-row>
                <a-row>
                    <a-form-item label="角色说明">
                        <a-textarea rows="4" v-decorator="['remarks',{rules:[{required:true,message:'请输入角色说明'}]}]" placeholder="请输入" style="width:600px" ></a-textarea>
                    </a-form-item>
                </a-row>
                <a-row class="form-btn-group" style="padding-left:80px">
                   <a-button class="btn-save" @click="submit" :loadding="submitLoadding" :disabled="submitLoadding"><a-icon v-if="!submitLoadding" type="save" />保存</a-button>
                   <a-button class="btn-cancel"  @click="$router.back()">取消</a-button>
               </a-row>
            </a-form>
        </section>
    </section>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    beforeCreate(){
        this.form = this.$form.createForm(this)
    },
    computed:{
        rid(){
            return this.$route.query.id
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    created(){
        this.rid && this.queryRoleDetails()
    },
    methods:{
        queryRoleDetails(){
            this.$api.queryRoleDetails({id:this.rid}).then(res =>{
                if(res.code === 200){
                   
                    let role = (res.data && res.data.role) || []
                    this.form.setFieldsValue({
                        name:role.name,
                        remarks:role.remarks
                    })
                }
            })
        },
        submit(){
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    if(this.rid){
                        formData.id = this.rid
                    }
                    let params = {
                      role:  formData
                    }
                    let apiName = this.rid ? 'updateRole' : 'insertRole'
                    this.$api[apiName](params).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>